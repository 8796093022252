export enum ESocketMeetupEventName {
	STATUS_UPDATED = 'meetupStatusUpdated'
}

export enum ESocketLessonEventName {
	STATUS_UPDATED = 'lessonStatusUpdated',
	INITIAL_STATUS = 'initialLessonStatus'
}

export enum ESocketS3VideoEventName {
	STATUS_UPDATED = 's3VideoStatusUpdated'
}

export default {};
