import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/layouts/components/Main.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/layouts/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/layouts/Header/HeaderContent.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/styles/styles.css");
