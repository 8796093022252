import styled from 'styled-components';

import { devices } from '@/styles/variables';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 32px 0;
	@media ${devices.laptop} {
		flex-direction: row;
		gap: 24px;
	}
	p {
		font-size: 12px;
		line-height: 12px;
		margin: 0 0;
		@media ${devices.laptop} {
			font-size: 14px;
			line-height: 14px;
		}
	}
`;

const FooterCopyright = () => {
	return (
		<Container>
			<p>© 2017-2025 JR Academy Pty Ltd. All rights reserved.</p>
			<p>ABN 26621887572</p>
		</Container>
	);
};

export default FooterCopyright;
