import { useScrollTrigger } from '@mui/material';
import { usePathname } from 'next/navigation';
import React from 'react';

import {
	PATH_ADMIN,
	PATH_INSTRUCTOR,
	PATH_P3_PROJECT,
	PATH_PRIVATE_TUTORING
} from '@/constants/common';
import { isProd } from '@/constants/env';
import { color } from '@/styles/variables';

interface Props {
	window?: () => Window;
	children: React.ReactElement;
}

export default function ElevationScroll(props: Props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined
	});

	const pathname = usePathname();

	const getBgColor = () => {
		const alwaysWhitePages: string[] = [PATH_P3_PROJECT, PATH_PRIVATE_TUTORING];
		const alwaysWhitePagesStartsWith = [PATH_ADMIN, ...(isProd ? [] : [PATH_INSTRUCTOR])];
		if (alwaysWhitePages.includes(pathname)) {
			return color.whiteColor;
		}
		if (
			trigger ||
			alwaysWhitePagesStartsWith.some(startsWith => pathname.startsWith(startsWith))
		) {
			return color.whiteColor;
		}
		return color.bgColor;
	};

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
		style: {
			backgroundColor: getBgColor(),
			transition: trigger ? `all 200ms ease-in-out` : undefined,
			boxShadow: 'none'
		}
	});
}
