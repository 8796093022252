import { useEffect } from 'react';

import MessageWebSocketManager from '@/utils/webSocket/messageWebsocketManager';

const useSocketMessageStatus = (userId?: string) => {
	useEffect(() => {
		if (userId) {
			const webSocketManager = new MessageWebSocketManager();
			webSocketManager.listenToLessonLiveStatus();
			webSocketManager.initialLessonLiveStatus();
			webSocketManager.listenToS3VideoStatus();

			return () => {
				webSocketManager.disconnect();
			};
		}
		return () => {};
	}, [userId]);
};

export default useSocketMessageStatus;
